<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            title="O subdistrito de Campinas"
            titleColor="#000"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                O subdistrito de Campinas pertence ao Distrito de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_aguas_claras' }"
                  >Águas Claras</router-link
                >, que por sua vez faz parte do Município de Mariana-MG.
                Situa-se a 9 km de distância do núcleo urbano de Águas Claras, a
                47 km do centro de Mariana e a 159 km de Belo Horizonte, capital
                do estado de Minas Gerais. A região caracteriza-se pelo relevo
                montanhoso coberto por extensas áreas de mata e grande
                quantidade de maciços rochosos. O Rio Gualaxo do Norte atravessa
                o território no sentido leste, em meio aos terrenos produtivos
                caracterizados por sítios e fazendas, entre extensões de
                recursos naturais não explorados.
              </p>
              <p>
                A formação do núcleo urbano remonta, provavelmente, ao final do
                século XIX, considerando-se que, assim como
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_pedras' }"
                  >Pedras</router-link
                >, as informações sobre o povoamento começam a aparecer no
                arquivo da Câmara Municipal de Mariana naquela
                época<sup>1</sup>.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="images__column d-flex flex-column justify-center align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_campinas_01.png"
          />
          <p>
            Vista parcial da comunidade onde se observa o Rio Gualaxo do Norte
            passando pelo território. Foto: Flora Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between flex-md-row-reverse">
        <v-col
          cols="12"
          md="4"
          class="images__column d-flex flex-column justify-start align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_campinas_02.png"
          />
          <p>
            Vista parcial da de Campinas onde se observa o relevo montanhoso
            coberto por mata e áreas de pastagem. Foto: Flora Passos, 2018.
          </p>
          <img
            width="100%"
            src="../../assets/sobre_territorio_campinas_03.png"
          />
          <p>
            Vista da área central de Campinas, onde se destaca a Capela de Nossa
            Senhora Aparecida, situada em ponto elevado. Foto: Jornal A Sirene,
            2017.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex align-start justify-start">
          <Card borderColor="#e06919" class="mt-5">
            <template v-slot:conteudo>
              <p>
                A concentração de casas e equipamentos de uso comunitário, como
                a Capela de Nossa Senhora Aparecida, a Assembleia de Deus, a
                Escola Municipal, o posto de saúde, a associação de moradores
                (antiga escola municipal), a quadra poliesportiva, localizam-se
                a norte do território, estando os sítios e fazendas situados ao
                longo do Rio Gualaxo do Norte, principalmente em sua margem
                norte.
              </p>
              <p>
                A ocupação da comunidade de Campinas é semelhante à de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_paracatu_de_baixo' }"
                  >Paracatu de Baixo</router-link
                >, com casas implantadas de forma centralizada nos terrenos
                amplos, delimitados por cerca, com quintais onde são cultivados
                pomares e hortas. Os plantios dos quintais compõem uma massa
                verde contínua junto com a vegetação das matas do entorno que
                recobrem as encostas.
              </p>
              <p>
                As casas seguem o padrão das construções típicas rurais da
                região, com planta quadrada, telhados dispostos em duas ou
                quatro águas, poucos vãos de portas e janelas, de dimensões
                pequenas, fechados em sua maioria com folhas cegas de madeira,
                havendo também esquadrias metálicas. Em relação às coberturas,
                em muitas casas as telhas cerâmicas foram substituídas por
                telhas de fibrocimento.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="images__column d-flex flex-column justify-center align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_campinas_04.png"
          />
          <p>
            Vista parcial da área onde se situa a atual Escola Municipal de
            Campinas, em segundo plano na imagem. Foto: Flora Passos, 2018.
          </p>
          <img
            width="100%"
            src="../../assets/sobre_territorio_campinas_05.png"
          />
          <p>
            Vista aproximada da antiga Escola Municipal de Campinas, onde se
            observa o estado precário de conservação. Foto: Flora Passos, 2018.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O conjunto edificado foi implantado na base da encosta, com as
                ruas acompanhando as curvas de nível. Na direção norte e sul,
                desenvolve-se a via principal do núcleo urbano, a Rua do
                Cruzeiro, e na direção leste e oeste, margeando o Rio Gualaxo, a
                Rua Santa Cruz dá acesso aos terrenos rurais, consistindo na
                estrada que conduz a Gesteira, território também atingido do
                município de Barra Longa.
              </p>
              <p>
                As técnicas construtivas presentes nas edificações antigas de
                Campinas compõem-se de estrutura autônoma de madeira e vedação
                com tijolos maciços, material utilizado e difundido em Minas
                Gerais no final do século XIX em substituição ao uso dos
                fechamentos com pau a pique, das alvenarias de adobe e de pedra
                — reforçando o entendimento de que o povoamento foi iniciado nos
                oitocentos.
              </p>
              <p>
                Muitas fazendas da região possuem, além da residência, um
                conjunto de construções e benfeitorias relacionadas à criação de
                gado. A pecuária surge na fase inicial da ocupação de Minas
                junto com as atividades de plantio, principalmente a criação de
                suínos. Próximos da área de maior concentração de casas do
                subdistrito, encontram-se antigas fazendas com estruturas como
                moinho, curral, muros feitos em pedras e até mesmo pocilga com
                alvenaria de pedras. Os encontros, socialização e atividades de
                diversão da população ocorrem no adro da capela, na praça, na
                quadra poliesportiva e nos bares. A cachoeira e o rio também
                eram locais de diversão e socialização antes do rompimento da
                barragem, ficando comprometidos após o desastre.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="8"
          class="d-flex flex-column flex-md-row justify-center align-center"
        >
          <img
            class="ma-1"
            src="../../assets/sobre_territorio_campinas_06.png"
            alt=""
          />
          <img
            class="ma-1"
            src="../../assets/sobre_territorio_campinas_07.png"
            alt=""
          />
          <img
            class="ma-1"
            src="../../assets/sobre_territorio_campinas_08.png"
            alt=""
          />
          <img
            class="ma-1"
            src="../../assets/sobre_territorio_campinas_09.png"
            alt=""
          />
        </v-col>
        <v-col cols="12" md="8">
          <p style="font-size: 0.5em; width: 100%">
            Vistas de casas com tipologia típica rural do território de Mariana,
            com telhados cerâmicos, janelas de madeira, implantadas no terreno
            cercadas por amplos quintais onde se situa a horta, o jardim, as
            árvores frutíferas, onde são criados animais e se situam as
            edificações acessórias de uso rural, como os currais, galinheiros e
            pocilgas. Fotografias: Acervo de vistoria técnica realizada pela
            Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="8"
          class="d-flex flex-column flex-md-row justify-center align-start"
        >
          <div class="d-flex flex-column justify-center align-center">
            <v-img
              class="ma-2"
              src="../../assets/sobre_territorio_campinas_10.png"
              alt=""
            ></v-img>
            <p style="font-size: 0.6em; text-align: center;">
              Residência de um terreno localizado na área rural de Campinas,
              construída com tijolos de adobe sobre embasamento de pedras.
              Fotografias: Acervo de vistoria técnica realizada pela Cáritas,
              2019.
            </p>
          </div>
          <div class="d-flex flex-column justify-center align-center">
            <v-img
              class="ma-2"
              src="../../assets/sobre_territorio_campinas_11.png"
              alt=""
            ></v-img>
            <p style="font-size: 0.6em; text-align: center;">
              Paiol construído com estrutura e fechamento de madeira, situado em
              um sítio da área rural de Campinas, pertencente ao conjunto de
              edificações rurais. Fotografias: Acervo de vistoria técnica
              realizada pela Cáritas, 2019.
            </p>
          </div>
          <div class="d-flex flex-column justify-center align-center">
            <v-img
              class="ma-2"
              src="../../assets/sobre_territorio_campinas_12.png"
              alt=""
            ></v-img>
            <p style="font-size: 0.6em; text-align: center;">
              Pocilga construída com alvenaria de pedras situada em um sítio da
              área rural de Campinas, pertencente ao conjunto de edificações
              rurais. Fotografias: Acervo de vistoria técnica realizada pela
              Cáritas, 2019.
            </p>
          </div>
          <div class="d-flex flex-column justify-center align-center">
            <v-img
              class="ma-2"
              src="../../assets/sobre_territorio_campinas_13.png"
              alt=""
            ></v-img>
            <p style="font-size: 0.6em; text-align: center;">
              Galinheiro construído sobre embasamento de pedras situado em um
              sítio da área rural de Campinas, pertencente ao conjunto de
              edificações rurais. Fotografias: Acervo de vistoria técnica
              realizada pela Cáritas, 2019.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A comunidade de Campinas manteve, ao longo do tempo, a vocação
                para desenvolvimento de atividades agropecuárias, onde a maioria
                dos produtores cria gado leiteiro e abastece a linha de coleta
                da Associação de Produtores de Leite de Águas Claras.
              </p>
              <p style="text-align: center;">
                <strong>Autora: Ana Paula Alves Ferreira <sup>2</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="8"
          class="d-flex flex-column flex-md-row justify-center align-center"
        >
          <div class="d-flex flex-column justify-center align-center">
            <v-img
              class="ma-2"
              src="../../assets/sobre_territorio_campinas_14.png"
              alt=""
            ></v-img>
            <p style="font-size: 0.6em; text-align: center; max-width: 90%;">
              Moinho situado em um sítio da área rural de Campinas, pertencente
              ao conjunto de edificações rurais. Fotografias: Acervo de vistoria
              técnica realizada pela Cáritas, 2019.
            </p>
          </div>
          <div class="d-flex flex-column justify-center align-center">
            <v-img
              class="ma-2"
              src="../../assets/sobre_territorio_campinas_15.png"
              alt=""
            ></v-img>
            <p style="font-size: 0.6em; text-align: center; max-width: 90%;">
              Vista de trecho do sítio por onde passa o corpo hídrico de onde
              vem a água que movimentava o moinho (observado no canto superior
              direito da imagem). Fotografias: Acervo de vistoria técnica
              realizada pela Cáritas, 2019.
            </p>
          </div>
          <div class="d-flex flex-column justify-center align-center">
            <v-img
              class="ma-2"
              src="../../assets/sobre_territorio_campinas_16.png"
              alt=""
            ></v-img>
            <p style="font-size: 0.6em; text-align: center; max-width: 100%;">
              Vista da quadra poliesportiva de Campinas. Foto: Flora Passos,
              2018.
            </p>
          </div>
          <div class="d-flex flex-column justify-center align-center">
            <v-img
              class="ma-2"
              src="../../assets/sobre_territorio_campinas_17.png"
              alt=""
            ></v-img>
            <p style="font-size: 0.6em; text-align: center; max-width: 90%;">
              Vista de um trecho do Rio Gualaxo do NORTE que atravessa o
              território de Campinas. Foto: Flora Passos, 2018.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container-fluid mb-4">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> As informações a respeito do histórico e da
              caracterização de Campinas tiveram como base principal o
              Diagnóstico preliminar dos bens culturais identificados no
              território atingido em Mariana pelo rompimento da barragem de
              Fundão, elaborado em 2017 pela empresa Pólen Consultoria
              Patrimônio e Projetos Ltda. O Diagnóstico foi encomendado pela
              Comissão dos Atingidos e pela Cáritas Brasileira MG e juntamente
              com os dados do cadastro realizado pela Cáritas, possibilitou um
              entendimento mais amplo das relações culturais, sociais e
              econômicas que foram consolidadas nesse território ao longo dos
              séculos. O trabalho abrange aspectos geográficos, históricos,
              arqueológicos, arquitetônicos, antropológicos e museológicos.
              Contudo, ainda se trata de um estudo e diagnóstico preliminar,
              sendo necessário a ampliação do conhecimento sobre as comunidades
              atingidas.
            </p>
            <p class="ma-0">
              <sup>2</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
};
</script>

<style scoped>
.images__column {
  width: 100%;
}
.images__column p {
  font-size: 0.5em;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 5em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
